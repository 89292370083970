import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import React from 'react';

import errorImage from '../../assets/images/error.jpg';

const ErrorFallbackComponent = () => {
  const theme = useTheme();
  const smallerDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const tryAgain = () => {
    window.location.reload();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Card>
        <CardContent
          style={{
            padding: '50px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: smallerDevice ? 'column' : 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={errorImage}
            alt="error"
            style={{ height: '200px', backgroundSize: 'cover' }}
          />
          <div style={{ paddingLeft: '20px', paddingTop: smallerDevice && '50px' }}>
            <Typography variant="h4" style={{ paddingBottom: '10px' }}>
              Oops...!
            </Typography>
            <Typography variant="h6" style={{ paddingBottom: '20px' }}>
              Something Went Wrong!
            </Typography>
            <Button onClick={tryAgain} endIcon={<CachedIcon />}>
              Refresh
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ErrorFallbackComponent;
