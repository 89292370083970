import { Switch, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { showConfirmDialog } from '../ConfirmationDialog';
import './labels.css';
import { useMutationApi } from '../../hooks/useApis';
import { useSelector } from 'react-redux';

function LanguageToggle() {
  const { i18n, t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const { mutate: mutateLanguageChange } = useMutationApi(() => {
    window.location.reload();
  });

  const changeLanguage = async (e) => {
    try {
      await showConfirmDialog({
        title: t('auth.your_page_will_refresh'),
      });
      localStorage.setItem('language', !e.target.checked ? 'ar' : 'en');
      if (isLoggedIn)
        mutateLanguageChange({
          url: '/api/system-users/preferred-language/',
          type: 'post',
          req: {
            preferred_language: !e.target.checked ? 'ar' : 'en',
          },
        });
      else window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <IOSSwitch
        id="language-toggle"
        type="checkbox"
        checked={i18n.language === 'ar'}
        onChange={changeLanguage}
      />
    </div>
  );
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 58,
    height: 30,
    padding: 0,
    margin: theme.spacing(2),
  },
  switchBase: {
    padding: 1,
    backgroundColor: 'white',

    '&$checked': {
      transform: 'translateX(0px)',

      '& + $track': {
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 28,
    height: 28,
    backgroundColor: theme.palette.common.white,
  },
  track: {
    borderRadius: 30 / 2,
    backgroundColor: `${theme.palette.secondary.light} !important`,
    border: '1px solid gray !important',
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: `${classes.root} language-toggle-thumb`,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default LanguageToggle;
