import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useMediaQuery, useTheme } from '@material-ui/core';
import routes from '../routes';

const DESKTOP_WIDTH = 300;
const TABLET_WIDTH = 260;
const MOBILE_WIDTH = 240;
const COLLAPSED_WIDTH = 70;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(32),
    overflowX: 'hidden',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(30),
    },
  },
  desktopShift: {
    marginLeft: DESKTOP_WIDTH,
    width: `calc(100% - ${DESKTOP_WIDTH}px)`,
  },
  tabletShift: {
    marginLeft: TABLET_WIDTH,
    width: `calc(100% - ${TABLET_WIDTH}px)`,
  },
  collapsedShift: {
    marginLeft: COLLAPSED_WIDTH,
    width: `calc(100% - ${COLLAPSED_WIDTH}px)`,
  },
  mobileShift: {
    marginLeft: 0,
    width: '100%',
  },
});

const hiddenSidebarRXs = routes.map((route) => route.hideSidebarRX).filter(Boolean);

function MainLayout(props) {
  const { classes, children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const drawerWidth = isMobile
    ? MOBILE_WIDTH
    : isTablet
    ? TABLET_WIDTH
    : DESKTOP_WIDTH;

  const [state, setState] = useState({
    open: true,
    width: drawerWidth,
  });

  useEffect(() => {
    if (isMobile)
      setState({
        ...state,
        open: false,
        width: 0,
      });
    else {
      setState({
        ...state,
        open: true,
        width: drawerWidth,
      });
    }
  }, [drawerWidth]);

  const handleToggleDrawer = () => {
    setState((prevState) => {
      return {
        open: !prevState.open,
        width: prevState.open ? (isMobile ? 0 : COLLAPSED_WIDTH) : drawerWidth,
      };
    });
  };

  // Both of the below classes will only apply on DESKTOP UI.
  // For mobile UI the drawer is Temporary so it does not need to have content shift
  let shiftClassName = '';
  switch (drawerWidth) {
    case DESKTOP_WIDTH:
      shiftClassName = state.open ? classes.desktopShift : classes.collapsedShift;
      break;

    case TABLET_WIDTH:
      shiftClassName = state.open ? classes.tabletShift : classes.collapsedShift;
      break;

    case MOBILE_WIDTH:
      shiftClassName = classes.mobileShift;
      break;

    default:
      break;
  }

  const renderSidebar = () => {
    for (let regExp of hiddenSidebarRXs) {
      if (regExp?.test(location.pathname)) return null;
    }
    return (
      <Sidebar
        open={state.open}
        drawerWidth={state.width}
        isMobile={isMobile}
        handleToggleDrawer={handleToggleDrawer}
      />
    );
  };

  return (
    <Fragment>
      {renderSidebar()}
      <div className={classes.root}>
        <Header
          location={location}
          drawerWidth={isMobile || !renderSidebar() ? 0 : state.width}
          handleToggleDrawer={handleToggleDrawer}
        />
        <main
          className={classNames(classes.content, shiftClassName)}
          style={!renderSidebar() ? { width: '100%', marginLeft: 0 } : {}}
        >
          {children}
        </main>
      </div>
    </Fragment>
  );
}

export default withStyles(styles)(MainLayout);
