import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    filter: { userRole: 'Investor' },
    search: '',
  },
  reducers: {
    setFilters: (state, action) => {
      state.filter = action.payload;
      return state;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
  },
});

export const { setFilters, setSearch } = userSlice.actions;

export default userSlice.reducer;
