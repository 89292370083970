import React from 'react';
import logo from '../../assets/images/logo/logo-white.svg';

export default function IbdaxLogo({
  primaryColor = '#0e4e96',
  secondaryColor = '#0db14b',
  height = '20px',
  width,
  ...rest
}) {
  return <img src={logo} width={width} height={height} {...rest} />;
}
