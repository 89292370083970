import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import {
  Collapse,
  Drawer,
  List,
  ListItem as MUIListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSidebarStyles } from './styles';
import routes from '../../routes';
import InitialsIcon from '../InitialsIcon';

const SidebarComponent = (props) => {
  const { open, drawerWidth, isMobile, handleToggleDrawer } = props;
  const pagePathname = window.location.pathname;

  const { t, i18n } = useTranslation();
  const classes = useSidebarStyles();
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const { userType, userName, initials, email, userId } = useSelector((state) => {
    return {
      userId: state.user.id,
      userType: state.user.userType,
      initials: state.user.english_name
        .split(' ')
        .map((c) => c[0])
        .join(''),
      userName:
        i18n.language === 'en' ? state.user.english_name : state.user.arabic_name,
      email: state.user.email,
    };
  });

  const onListItemClick = () => {
    if (isMobile) handleToggleDrawer();
  };

  const ListItem = (props) => {
    if (open) return <MUIListItem {...props} />;
    const textElement = props.children?.find(({ type }) => type === ListItemText);
    const text = textElement?.props?.primary || textElement?.props?.secondary;

    if (!text) return <MUIListItem {...props} />;

    return (
      <Tooltip title={text} enterDelay={500}>
        <MUIListItem {...props} />
      </Tooltip>
    );
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      classes={{
        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      PaperProps={{
        style: { width: drawerWidth },
      }}
      open={open}
      onClose={handleToggleDrawer}
    >
      <div style={{ padding: '20px' }}>
        {open && (
          <>
            <div style={{ marginTop: 12 }}>
              <Typography
                className={classes.font}
                style={{ opacity: 0.5, fontSize: '2rem' }}
              >
                #{userId}
              </Typography>
              <Typography className={classes.font}>{userName}</Typography>
              <Typography style={{ color: '#fff' }}>{email}</Typography>
            </div>
          </>
        )}
      </div>
      <List className={classes.scroll}>
        {routes
          .map((sidebarItem, idx) => {
            if (sidebarItem.hiddenInSidebar) return null;
            if (!sidebarItem.roles?.includes(userType)) return null;
            if (sidebarItem.showAsDropDownInSidebar) {
              return (
                <Fragment key={idx}>
                  <ListItem
                    button
                    id={sidebarItem.name}
                    onClick={() =>
                      setActiveSubmenu(
                        sidebarItem.name === activeSubmenu ? '' : sidebarItem.name
                      )
                    }
                    style={{
                      backgroundColor: pagePathname.startsWith(sidebarItem.path)
                        ? 'rgba(72, 240, 120, 0.15)'
                        : null,
                    }}
                  >
                    <ListItemIcon className={classes.icon}>
                      <sidebarItem.icon />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className={classNames(classes.font, {
                        [classes.dNone]: !open,
                      })}
                      primary={t(`components.sidebar.${sidebarItem.name}`)}
                    />
                    {activeSubmenu === sidebarItem.name ? (
                      <ExpandLess
                        className={classNames(classes.icon, {
                          [classes.dNone]: !open,
                        })}
                      />
                    ) : (
                      <ExpandMore
                        className={classNames(classes.icon, {
                          [classes.dNone]: !open,
                        })}
                      />
                    )}
                  </ListItem>
                  <Collapse
                    in={activeSubmenu === sidebarItem.name}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding className={classes.nested}>
                      {sidebarItem.children.map((child, idx) => (
                        <Link
                          className={classes.btnlink}
                          onClick={onListItemClick}
                          to={child.path}
                          id={child.name}
                          key={idx}
                        >
                          <ListItem
                            button
                            style={{
                              backgroundColor: pagePathname.startsWith(child.path)
                                ? 'rgba(72, 240, 120, 0.4)'
                                : null,
                            }}
                          >
                            <ListItemIcon className={classes.icon}>
                              <child.icon />
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              className={classNames(classes.font, {
                                [classes.dNone]: !open,
                              })}
                              primary={t(`components.sidebar.${child.name}`)}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              );
            }

            return (
              <Link
                to={sidebarItem.path}
                id={sidebarItem.name}
                className={classes.btnlink}
                onClick={onListItemClick}
                key={idx}
              >
                <ListItem
                  button
                  className={classes.my2}
                  style={{
                    backgroundColor: pagePathname.startsWith(sidebarItem.path)
                      ? 'rgba(72, 240, 120, 0.4)'
                      : null,
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    <sidebarItem.icon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classNames(classes.font, { [classes.dNone]: !open })}
                    primary={t(`components.sidebar.${sidebarItem.name}`)}
                  />
                </ListItem>
              </Link>
            );
          })
          .filter(Boolean)}
      </List>
      <div className={classes.copyright}>
        <Typography
          className={classNames(classes.copyrightText, { [classes.dNone]: !open })}
          variant="body2"
          color="textSecondary"
          align="center"
        >
          {' © '}
          {t('auth.all_copyright_reserved')}
          {'.'}
        </Typography>
      </div>
    </Drawer>
  );
};

const Sidebar = withTranslation()(SidebarComponent);

export default Sidebar;
