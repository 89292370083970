import { Box } from '@material-ui/core';
import React from 'react';
import LanguageToggle from '../components/LanguageToggleButton';

const EmptyLayout = (props) => {
  return (
    <div style={{ height: 'inherit' }}>
      <Box
        sx={{ padding: '30px 30px 0 30px' }}
        display="flex"
        flexDirection="row-reverse"
      >
        <LanguageToggle />
      </Box>
      {props.children}
    </div>
  );
};

export default EmptyLayout;
