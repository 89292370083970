import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Collapse, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Container as ModalContainer } from 'react-modal-promise';
import { ErrorBoundary } from 'react-error-boundary';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

import { createCustomTheme } from './helper/theme';
import routes from './routes';
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import UserSessionManager from './components/UserSessionManager';

import './styles/App.css';
import AlertDialog from './components/AlertDialog';
import AccountDialog from './components/AccountDialog';
import ErrorFallbackComponent from './components/ErrorFallbackComponent';

const SnackBarProps = {
  maxSnack: 3,
  classes: {
    root: 'SnackBarCustomFont',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  TransitionComponent: Collapse,
};

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppComponent = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isFetched = useSelector((state) => state.user.isFetched);
  const preferredLanguage = useSelector((state) => state.user.preferred_language);
  const noAuthRoutes = [];

  if (isLoggedIn && preferredLanguage !== i18n.language) {
    i18n.changeLanguage(preferredLanguage).then(() => {
      localStorage.setItem('language', preferredLanguage);
    });
    return null;
  }

  const errorHandler = (error, errorInfo) => {
    console.log('errorInfo', errorInfo);
    console.error(error);
  };

  const nonProtectedRoutes = routes
    .filter((r) => !r.isProtected)
    .map((route, idx) => {
      noAuthRoutes.push(route.path);
      return (
        <Route key={idx} path={route.path} exact={route.exact}>
          <ErrorBoundary
            FallbackComponent={ErrorFallbackComponent}
            onError={errorHandler}
          >
            <route.component />
          </ErrorBoundary>
        </Route>
      );
    });

  const protectedRoutes = routes
    .filter((r) => r.isProtected)
    .map((route, idx) => {
      return (
        <Route key={idx} path={route.path} exact={route.exact}>
          <ErrorBoundary
            FallbackComponent={ErrorFallbackComponent}
            onError={errorHandler}
          >
            <route.component />
          </ErrorBoundary>
        </Route>
      );
    });

  const redirects = noAuthRoutes.map((route, idx) => (
    <Redirect exact key={idx} from={route} to="/market" />
  ));
  const isLogin = isLoggedIn && isFetched;
  const Layout = isLogin ? MainLayout : EmptyLayout;
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider
        theme={createCustomTheme(i18n.language === 'ar' ? 'rtl' : 'ltr')}
      >
        <div dir={i18n.language === 'ar' && 'rtl'}>
          <SnackbarProvider {...SnackBarProps}>
            <CssBaseline />
            <Router>
              <UserSessionManager>
                <Layout>
                  <Suspense fallback={<div />}>
                    <AlertDialog />
                    <AccountDialog />
                    <Switch>
                      {isLogin && protectedRoutes}
                      {isLogin && redirects}
                      {isLogin && <Redirect exact from="/" to="/market" />}
                      {!isLogin && nonProtectedRoutes}
                      {!isLogin && <Redirect from="/" to="/login" />}
                      <Route component={() => <div>Not Found</div>} />
                    </Switch>
                  </Suspense>
                </Layout>
                <ModalContainer />
              </UserSessionManager>
            </Router>
          </SnackbarProvider>
        </div>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

const App = withTranslation()(AppComponent);

export default App;
