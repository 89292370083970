import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiManager from '../../utils/apiManager';

import { setIBANDetails } from '../../store/account';

export default function AccountDialog() {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user);
  const isUserInvestor = () => user?.user_type?.includes('Investor');

  useEffect(() => {
    if (!isUserInvestor()) {
      return;
    }
    apiManager.get('/api/wallet/get-iban/').then((res) => {
      if (res.data) {
        dispatch(setIBANDetails(res.data));
      }
    });
  }, []);

  return null;
}
