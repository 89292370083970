import { createAsyncThunk } from '@reduxjs/toolkit';
import apiManager from '../../utils/apiManager';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const res = await apiManager.get('/api/system-users/view-profile/');
  if (res.data?.results?.[0]) {
    return res.data?.results[0];
  }
  throw new Error('Failed to resolve user');
});
