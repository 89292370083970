import { createSlice } from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
  name: 'market',
  initialState: {
    filter: {},
    search: '',
    showWatchlist: false,
  },
  reducers: {
    setFilters: (state, { payload }) => {
      state.filter = payload;
      return state;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
      return state;
    },
    setShowWatchlist: (state, { payload }) => {
      state.showWatchlist = payload;
      return state;
    },
  },
});

export const { setFilters, setSearch, setShowWatchlist } = ordersSlice.actions;

export default ordersSlice.reducer;
