import { createSlice, combineReducers } from '@reduxjs/toolkit';

import createNewPropertySlice from './create-new-property';

export const propertySlice = createSlice({
  name: 'data',
  initialState: {
    filter: {},
    search: '',
    records: {
      list: {},
      totalRecords: null,
      recordsNotFound: false,
      isLoading: true,
    },
    properties: {
      items: {},
    },
    owners: {
      list: {},
      totalRecords: null,
      recordsNotFound: false,
      isLoading: true,
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filter = action.payload;
      return state;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
    resetRecords: (state, action) => {
      state = {
        ...state,
        filter: {},
        search: '',
        records: {
          list: {},
          totalRecords: null,
          recordsNotFound: false,
          isLoading: true,
        },
      };
      return state;
    },
    setPropertyOwners: (state, action) => {
      state.records.list[action.payload.propertyId].property_owners =
        action.payload.property_owners;
      return state;
    },
  },
});

export const { setFilters, setSearch, setPropertyOwners, resetRecords } =
  propertySlice.actions;

export default combineReducers({
  [propertySlice.name]: propertySlice.reducer,
  [createNewPropertySlice.name]: createNewPropertySlice.reducer,
});
