import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

export const ConfirmTextFieldError = ({ confirmationText }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ErrorIcon style={{ fontSize: '0.9rem' }} />
      <Typography style={{ paddingLeft: '6px', fontSize: '12px' }}>
        {t('components.confirmation_text_field_error.confirmation_text_part_1')} "
        {confirmationText}"{' '}
        {t('components.confirmation_text_field_error.confirmation_text_part_2')}
      </Typography>
    </Box>
  );
};
