import { lazy } from 'react';
import {
  ListAlt,
  Person,
  PersonAdd,
  People,
  AnnouncementOutlined,
  AssignmentLate,
  Money,
  ViewModule,
  Apartment,
  AccountBalanceWallet,
  CompareArrows,
  AccountBalance,
  SupervisorAccount,
  Settings,
} from '@material-ui/icons';

import { ROLES } from '../constants/role';

// Pages
const Dividend = lazy(() => import('../pages/Dividend'));
const Users = lazy(() => import('../pages/Users'));
const Property = lazy(() => import('../pages/Property'));
const Profile = lazy(() => import('../pages/Profile'));
const Announcement = lazy(() => import('../pages/Announcement'));
const Trades = lazy(() => import('../pages/Trades'));
const Transfers = lazy(() => import('../pages/Transfers'));
const Orders = lazy(() => import('../pages/Orders'));
const InvestorMarket = lazy(() => import('../pages/InvestorMarket'));
const PropertyPage = lazy(() => import('../pages/InvestorMarket/PropertyPage'));
const Holdings = lazy(() => import('../pages/Holdings'));
const Accounts = lazy(() => import('../pages/Account'));
const InvestorAccount = lazy(() => import('../pages/Account/Investor'));
const FeeGroups = lazy(() => import('../pages/FeeGroups'));
const FeeGroupUsersListing = lazy(() =>
  import('../pages/FeeGroups/FeeGroupUsersListing')
);
const AppConfig = lazy(() => import('../pages/AppConfig'));

export const protectedRoutes = [
  {
    path: '/market/:id',
    component: PropertyPage,
    exact: true,
    hiddenInSidebar: true,
    roles: [ROLES.INVESTOR, ROLES.MANAGER, ROLES.ADMIN],
  },
  {
    name: 'market',
    path: '/market',
    component: InvestorMarket,
    icon: ListAlt,
    roles: [ROLES.INVESTOR, ROLES.MANAGER, ROLES.ADMIN],
  },
  {
    path: '/watchlist/:id',
    component: PropertyPage,
    exact: true,
    hiddenInSidebar: true,
  },
  {
    name: 'users',
    path: '/users',
    component: Users,
    icon: Person,
    showAsDropDownInSidebar: true,
    roles: [ROLES.ADMIN],
    children: [
      {
        name: 'application_users',
        path: '/users/application',
        icon: People,
      },
      {
        name: 'invited_users',
        path: '/users/invited',
        icon: PersonAdd,
      },
    ],
  },
  {
    name: 'properties',
    path: '/property',
    component: Property,
    icon: ListAlt,
    roles: [ROLES.MANAGER, ROLES.ADMIN],
  },
  {
    name: 'orders',
    path: '/orders',
    component: Orders,
    icon: ViewModule,
    roles: [ROLES.INVESTOR, ROLES.ADMIN],
  },
  {
    name: 'trades',
    path: '/trades',
    component: Trades,
    icon: ListAlt,
    roles: [ROLES.ADMIN],
  },
  {
    name: 'unit_transfers',
    path: '/unit-transfers',
    component: Transfers,
    icon: CompareArrows,
    roles: [ROLES.ADMIN],
  },
  {
    name: 'holdings',
    path: '/holdings',
    component: Holdings,
    icon: Apartment,
    roles: [ROLES.INVESTOR],
  },
  {
    name: 'dividends',
    path: '/dividend',
    component: Dividend,
    icon: AccountBalanceWallet,
    roles: [ROLES.MANAGER, ROLES.ADMIN],
  },
  {
    name: 'users_accounts',
    path: '/accounts/investor',
    component: InvestorAccount,
    icon: AccountBalance,
    roles: [ROLES.INVESTOR],
    exact: true,
  },
  {
    name: 'users_accounts',
    path: '/accounts',
    component: Accounts,
    icon: AccountBalance,
    roles: [ROLES.ADMIN],
    showAsDropDownInSidebar: true,
    children: [
      {
        name: 'management',
        path: '/accounts/management',
        icon: SupervisorAccount,
      },
      {
        name: 'ibdax_wallet',
        path: '/accounts/ibdax',
        icon: AccountBalanceWallet,
      },
    ],
  },
  {
    name: 'fee_groups',
    path: '/fee-groups',
    component: FeeGroups,
    icon: Money,
    roles: [ROLES.ADMIN],
    exact: true,
  },
  {
    name: 'announcements',
    path: '/announcements',
    component: Announcement,
    icon: AnnouncementOutlined,
    showAsDropDownInSidebar: true,
    roles: [ROLES.INVESTOR, ROLES.MANAGER, ROLES.ADMIN],
    children: [
      {
        name: 'property_announcements',
        path: '/announcements/property',
        icon: AssignmentLate,
      },
      {
        name: 'market_announcements',
        path: '/announcements/market',
        icon: AnnouncementOutlined,
      },
    ],
  },
  {
    path: '/fee-group-users/:id',
    component: FeeGroupUsersListing,
    exact: true,
    hiddenInSidebar: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    hiddenInSidebar: true,
    hideRoleSelectorRX: /^\/profile/,
    hideSidebarRX: /^\/profile/,
  },
  {
    name: 'app_config',
    path: '/app-config',
    component: AppConfig,
    icon: Settings,
    roles: [ROLES.ADMIN],
    exact: true,
  },
];
