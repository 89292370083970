import { useQuery, useMutation } from 'react-query';

import apiManager from '../utils/apiManager';

const getFetcher = (url, query) => {
  return apiManager.get(url, { params: { ...query } });
};

const mutationApi = ({ url, req, type, config }) => {
  return apiManager[type](url, req, config);
};

export function useGetApi({ url, key, query, options, staleTime, select }) {
  const keys = query ? [key, ...Object.entries(query)] : key;
  return useQuery(keys, () => getFetcher(url, query), {
    cacheTime: 0,
    staleTime: 0,
    select: select || ((data) => data.data),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useMutationApi(onSuccess, onError) {
  return useMutation(mutationApi, {
    onSuccess,
    onError,
  });
}
