import apiManager from '../utils/apiManager';

const refreshToken = (rToken) => {
  if (rToken) {
    return sessionStorage.setItem('refresh', rToken);
  }
  return sessionStorage.getItem('refresh');
};

const token = (token) => {
  if (token) {
    apiManager.defaults.headers.common['authorization'] = `Bearer ${token}`;
    return sessionStorage.setItem('access', `Bearer ${token}`);
  }
  return sessionStorage.getItem('access');
};

export const setToken = (_token, rToken) => {
  if (_token) token(_token);
  if (rToken) refreshToken(rToken);
};

export const getToken = () => {
  return [token(), refreshToken()];
};

export const clearAllTokens = () => {
  sessionStorage.removeItem('access');
  sessionStorage.removeItem('refresh');
  apiManager.defaults.headers.common['authorization'] = undefined;
  apiManager.interceptors.response.use(undefined, undefined);
};
