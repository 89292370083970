import * as Sentry from '@sentry/react';
if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: 'https://cb81d29a524289949319fe054702b1c3@o4506075607072768.ingest.sentry.io/4506081827291136',
    release: 'ibdax-frontend@' + process.env.npm_package_version,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}
