import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import apiManager from '../../utils/apiManager';
import { useSnackbar } from 'notistack';
import { HighlightOff } from '@material-ui/icons';

const validRequestTypes = ['post', 'patch', 'put', 'delete'];
let prevMsg = '';
export default function AlertDialog() {
  const [state, setState] = useState({
    open: false,
    message: '',
    extraInfo: '',
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const _enqueueSnackbar = (msg, variant = 'success') => {
    if (prevMsg !== msg)
      enqueueSnackbar(msg, {
        variant,
        autoHideDuration: 3000,
        action: (key) => (
          <Button
            size="small"
            variant="text"
            style={{ color: 'white' }}
            onClick={() => closeSnackbar(key)}
          >
            <HighlightOff />
          </Button>
        ),
      });
    prevMsg = msg;
  };

  useEffect(() => {
    const onSuccessResponse = (response) => {
      const { config } = response;

      if (response.status !== 200) return response;
      if (!validRequestTypes.includes(config.method)) return response;
      if (!response.data.message) return response;
      _enqueueSnackbar(response.data.message);
      return response;
    };
    const onErroredResponse = (err) => {
      const { response, config } = err;

      const status = response?.status;
      const data = response?.data;

      if (!validRequestTypes.includes(config.method)) throw err;
      if (!data) throw err;

      if (status === 406) {
        if (data.error) {
          _enqueueSnackbar(data.error, 'error');
          throw err;
        }
      }

      if ([401, 404, 403].includes(status)) {
        if (
          (data.detail && typeof data.detail === 'string') ||
          (data.message && typeof data.message === 'string')
        ) {
          _enqueueSnackbar(data.detail || data.message, 'error');
          throw err;
        }
      }

      if (status === 400) {
        if (data.non_field_errors) {
          let debugMessage = '';

          for (const field in data) {
            debugMessage +=
              '\n' +
              data[field].reduce((acc, value) => {
                acc += `\t - ${value}`;
                return acc;
              }, '');
          }
          setState({
            open: true,
            message: 'Sorry, An error occurred',
            extraInfo: debugMessage,
          });
          throw err;
        } else if (data.error) {
          if (typeof data.error === 'string') {
            _enqueueSnackbar(data.error, 'error');
            throw err;
          }
          const errorMsg = data.error.reduce((acc, _err) => `${acc} ${_err}`, '');
          setState({
            open: true,
            message: 'Sorry, An error occurred',
            extraInfo: errorMsg,
          });
          throw err;
        }
      }
      throw err;
    };

    apiManager.interceptors.request.use((config) => {
      prevMsg = '';
      return config;
    });
    apiManager.interceptors.response.use(onSuccessResponse, onErroredResponse);
  }, []);

  const handleClose = () => {
    setState({
      open: false,
      message: '',
      extraInfo: '',
    });
  };

  return (
    <Dialog open={state.open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
        {state.message}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {state.extraInfo}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
