import { createSlice } from '@reduxjs/toolkit';

export const feeGroupsSlice = createSlice({
  name: 'feeGroups',
  initialState: {
    filter: {},
    search: '',
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearch } = feeGroupsSlice.actions;

export default feeGroupsSlice.reducer;
