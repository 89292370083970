import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Fade,
  Box,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { MoreVert, Menu as MenuIcon } from '@material-ui/icons';
import { useTranslation, withTranslation } from 'react-i18next';

import IbdaxLogo from '../IbdaxLogo';
import { logoutUser, changeUserType as _changeUserType } from '../../store/user';
import { showConfirmDialog } from '../ConfirmationDialog';
import routes from '../../routes';
import LanguageToggle from '../LanguageToggleButton';
import i18n from 'i18next';
import { Alert } from '@material-ui/lab';

const styles = (theme) => ({
  toolbarRoot: {
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  title: {
    flexGrow: 1,
  },
  btnlink: {
    textDecoration: 'none',
  },
  userTypeTab: {
    maxWidth: 100,
    minWidth: 'auto',
    fontSize: 'smaller',
  },
  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #d8d8d8',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      paddingLeft: 10,
    },
    width: 'initial',
  },
});

const hiddenSidebarRXs = routes.map((route) => route.hideSidebarRX).filter(Boolean);
const hiddenRoleSelectorRXs = routes
  .map((route) => route.hideRoleSelectorRX)
  .filter(Boolean);

const Header = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const { classes, handleToggleDrawer, drawerWidth, location } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const user_type = useSelector((state) => state.user.user_type);
  const currentUserType = useSelector((state) => state.user.userType);
  const { userName, mobile_number, email, isEmailVerified } = useSelector(
    (state) => ({
      ...state.user,
      isEmailVerified: state.user.is_email_verified,
      initials: state.user.english_name
        .split(' ')
        .map((c) => c[0].toUpperCase())
        .join(''),
      userName:
        i18n.language === 'en' ? state.user.english_name : state.user.arabic_name,
    })
  );
  const { t } = useTranslation();
  const isUserInvestor = () => user_type?.includes('Investor');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    const language = localStorage.getItem('language');
    localStorage.clear();
    localStorage.setItem('language', language);
    sessionStorage.clear();
    dispatch(logoutUser());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUserType = async (value) => {
    if (!value || value === currentUserType) return;
    try {
      await showConfirmDialog({
        title: t('components.header.redirect_message'),
      });
      history.push('/');
      dispatch(_changeUserType(value));
      handleClose();
    } catch (err) {}
  };

  const renderRoleSelector = () => {
    if (user_type.length === 1) return null;
    for (let regExp of hiddenRoleSelectorRXs) {
      if (regExp?.test(location.pathname)) return null;
    }
    return (
      <>
        <section
          style={{
            width: '100%',
            marginTop: '20px',
            padding: '0 10px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            value={currentUserType}
            exclusive
            onChange={(e, value) => changeUserType(value)}
          >
            {user_type.map((type, idx) => (
              <ToggleButton
                style={{
                  padding: '4px 12px',
                  margin: 'auto 10px',
                  borderRadius: 8,
                  minWidth: 100,
                  color:
                    type === currentUserType
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                  backgroundColor:
                    type === currentUserType
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                }}
                value={type}
                aria-label={idx}
                key={idx}
              >
                {t(`components.header.${type}`)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </section>
      </>
    );
  };

  const renderSidebarMenuIcon = () => {
    for (let regExp of hiddenSidebarRXs) {
      if (regExp?.test(location.pathname)) {
        return null;
      }
    }
    return (
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleToggleDrawer}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    );
  };

  return (
    <AppBar
      position="fixed"
      className={classes.header}
      style={{
        left: i18n.language === 'en' ? `${drawerWidth}px` : 0,
        right: i18n.language === 'ar' && `${drawerWidth}px`,
      }}
    >
      {!isEmailVerified && (
        <Alert
          style={{
            justifyContent: 'center',
            zIndex: 10000,
            marginLeft: -30,
            borderRadius: 0,
          }}
          severity="warning"
        >
          <Link to="/profile">{t('components.header.verify_email_msg')}</Link>
        </Alert>
      )}
      <Toolbar disableGutters={true} classes={{ root: classes.toolbarRoot }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderSidebarMenuIcon()}
            <Link to="/">
              <div className={classes.logo}>
                <IbdaxLogo height="40px" />
              </div>
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LanguageToggle />
            <IconButton onClick={handleClick} disableRipple>
              <MoreVert id="menu" style={{ color: 'white' }} />
            </IconButton>
          </div>
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ minWidth: '104rem' }}
          MenuListProps={{
            style: { paddingTop: 22 },
          }}
          TransitionComponent={Fade}
        >
          <div
            style={{
              minWidth: 320,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <section
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color="textSecondary"
                  style={{
                    margin: '1px',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                  }}
                >
                  {userName}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ margin: '1px', fontWeight: '500' }}
                >
                  {mobile_number}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ margin: '1px', fontStyle: 'italic', fontWeight: '500' }}
                >
                  {email}
                </Typography>
              </section>
            </>
          </div>
          <section
            style={{
              width: '100%',
              padding: '0 8px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Link to="/profile" className={classes.btnlink}>
              <MenuItem onClick={handleClose}>
                {t('components.header.profile')}
              </MenuItem>
            </Link>
            |
            {isUserInvestor() && (
              <>
                <Link to="/accounts/investor" className={classes.btnlink}>
                  <MenuItem onClick={handleClose}>
                    {t('components.header.accounts')}
                  </MenuItem>
                </Link>
                |
              </>
            )}
            <Link to="/" className={classes.btnlink}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
              >
                {t('components.header.logout')}
              </MenuItem>
            </Link>
          </section>
          {renderRoleSelector()}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default withTranslation()(withStyles(styles)(Header));
